var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "card-button",
    attrs: {
      "padding": 0,
      "bordered": false
    }
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" 立即获取"), _c('b', [_vm._v("完整版")]), _vm._v("获得更多的功能 "), _c('Icon', {
    attrs: {
      "type": "md-arrow-forward"
    }
  })], 1), _c('Button', {
    staticStyle: {
      "width": "130px"
    },
    attrs: {
      "to": "http://xpay.exrick.cn/pay?xboot",
      "target": "_blank",
      "type": "success"
    }
  }, [_vm._v("升级账户")])], 1), _c('img', {
    staticClass: "right",
    attrs: {
      "src": require("@/assets/dashboard/process.png")
    }
  })])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };