import cardBar from "./components/cardBar.vue";
import cardArea from "./components/cardArea.vue";
import cardRadial from "./components/cardRadial.vue";
import cardLine from "./components/cardLine.vue";
import LineChart from "./components/line.vue";
import cardButton from "./components/cardButton.vue";
import cardProgress from "./components/cardProgress.vue";
import orderTable from "./components/orderTable.vue";
export default {
  name: "dashboard-3",
  components: {
    cardBar,
    cardArea,
    cardRadial,
    cardLine,
    cardButton,
    cardProgress,
    LineChart,
    orderTable
  },
  data() {
    return {};
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};