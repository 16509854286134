var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "dashboard-page"
  }, [_c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('cardBar', {
    staticClass: "margin",
    attrs: {
      "id": "income",
      "title": "总收入",
      "value": 25134,
      "prefix": "￥",
      "compare": "1.08%"
    }
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('cardArea', {
    staticClass: "margin",
    attrs: {
      "id": "out",
      "title": "总支出",
      "value": 3456,
      "status": "down",
      "compare": "0.83%"
    }
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('cardRadial', {
    staticClass: "margin",
    attrs: {
      "id": "order",
      "title": "订单总数",
      "value": 35268,
      "status": "down",
      "compare": "6.13%"
    }
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('cardLine', {
    staticClass: "margin",
    attrs: {
      "id": "users",
      "title": "用户增长",
      "value": 13.68,
      "decimalPlaces": 2,
      "prefix": "+",
      "compare": "10.68%",
      "suffix": "%"
    }
  })], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 16
    }
  }, [_c('LineChart', {
    staticClass: "margin"
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 8
    }
  }, [_c('cardButton', {
    staticClass: "margin"
  }), _c('cardProgress', {
    staticClass: "margin"
  })], 1)], 1), _c('Row', [_c('Col', {
    attrs: {
      "lg": 24,
      "xl": 24
    }
  }, [_c('orderTable', {
    staticClass: "margin"
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };